import PropTypes from 'prop-types';
import * as React from 'react';

function KoalaIcon(props) {
  return (
    <svg viewBox="0 0 174.5 146.2" {...props}>
      <path
        fill="#b3b0bc"
        d="M109.8 144.3h26c0-21.8-21.2-34.8-21.2-34.8H59.9s-21.2 13-21.2 34.8h26l22.6-9.1 22.5 9.1zm0 0"
      />
      <defs>
        <path id="a" d="M7.2 1.2h160.1v143.4H7.2z" />
      </defs>
      <clipPath id="b">
        <use xlinkHref="#a" overflow="visible" />
      </clipPath>
      <path
        d="M69.5 109.5h-9.7s-21.2 13-21.2 34.8h9.7c0-21.8 21.2-34.8 21.2-34.8zm0 0"
        clipPath="url(#b)"
        fill="#a29eae"
      />
      <path
        fill="#c7c5cb"
        clipPath="url(#b)"
        d="M64.7 16.7C47.8-5.7 16.3-.2 12.1 13.1c-.5 1.6-.1 3.4 1.1 4.6 1.8 1.8 1.8 4.7-.1 6.5-3.7 3.6-8.3 11-4.5 24.6C11.9 60.5 26.7 61.2 39 59.5l25.7-42.8zm45.1 0c16.9-22.4 48.4-16.9 52.6-3.6.5 1.6.1 3.4-1.1 4.6-1.8 1.8-1.8 4.7.1 6.5 3.7 3.6 8.3 11 4.5 24.6-3.3 11.9-18.1 12.5-30.4 10.9l-25.7-43zm0 0"
      />
      <path
        fill="#b3b0bc"
        d="M149.9 59.9c-1.9-12.8-7.7-25.3-16.6-35.3-5.2-5.9-11.2-10.6-17.8-14.1-2 1.8-4 3.8-5.7 6.1l25.7 42.9c4.7.7 9.8 1 14.4.4zM41.2 24.7c-8.9 10-14.7 22.5-16.6 35.3 4.7.5 9.7.3 14.4-.4l25.7-42.9c-1.8-2.3-3.7-4.4-5.7-6.1-6.6 3.4-12.6 8.2-17.8 14.1zm0 0"
      />
      <path
        fill="#f59caf"
        d="M145.2 34.4c0 7.9-6.4 14.4-14.4 14.4-7.9 0-14.4-6.4-14.4-14.4 0-7.9 6.4-14.4 14.4-14.4s14.4 6.5 14.4 14.4zm-87.2 0c0 7.9-6.4 14.4-14.4 14.4-7.9 0-14.4-6.4-14.4-14.4 0-7.9 6.4-14.4 14.4-14.4 3.8 0 7.5 1.5 10.2 4.2 2.7 2.7 4.2 6.4 4.2 10.2zm0 0"
      />
      <path
        d="M143.9 40.5c-2.8-5.7-6.3-11.1-10.6-15.9-1.4-1.6-2.9-3.1-4.4-4.5-7 .9-12.5 7-12.5 14.3 0 7.9 6.4 14.4 14.4 14.4 5.8 0 10.8-3.4 13.1-8.3zm0 0"
        fill="#f3879e"
      />
      <defs>
        <path id="c" d="M7.2 1.2h160.1v143.4H7.2z" />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <path
        d="M41.2 24.7c-4.3 4.8-7.8 10.2-10.6 15.9 2.3 4.9 7.3 8.3 13 8.3 7.9 0 14.4-6.4 14.4-14.4 0-7.3-5.4-13.3-12.5-14.3-1.5 1.4-2.9 2.9-4.3 4.5zm0 0"
        clipPath="url(#d)"
        fill="#f3879e"
      />
      <path
        d="M87.2 114.1c-85.4 0-61.1-102.5 0-102.5s85.5 102.5 0 102.5zm0 0"
        clipPath="url(#d)"
        fill="#c7c5cb"
      />
      <path
        d="M101.9 60.2l-.6-3.3c-1.3-6.7-7.2-11.5-14-11.5s-12.7 4.8-14 11.5l-.6 3.3c-1.8 8.8 5 17.1 14 17.1H88c8.9 0 15.6-8.2 13.9-17.1zm0 0"
        fill="#5d5970"
      />
      <path
        fill="#433f50"
        d="M59.9 52.7c-4.7 0-8.5 3.8-8.5 8.5 0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-2 1.7-3.7 3.7-3.7s3.7 1.7 3.7 3.7c0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-4.7-3.8-8.5-8.5-8.5zm54.7 0c-4.7 0-8.5 3.8-8.5 8.5 0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-2 1.7-3.7 3.7-3.7s3.7 1.7 3.7 3.7c0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-4.7-3.8-8.5-8.5-8.5zM98.6 82c-1.3 0-2.4 1.1-2.4 2.4 0 1.8-1.5 3.3-3.3 3.3-1.8 0-3.3-1.5-3.3-3.3 0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4c0 1.8-1.5 3.3-3.3 3.3-1.8 0-3.3-1.5-3.3-3.3 0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4 0 4.5 3.6 8.1 8.1 8.1 2.2 0 4.2-.9 5.7-2.3 1.5 1.4 3.5 2.3 5.7 2.3 4.5 0 8.1-3.6 8.1-8.1.1-1.3-1-2.4-2.4-2.4zm0 0"
      />
      <path
        fill="#e8e8e8"
        d="M131.4 75c0 5-5.1 9.1-11.5 9.1S108.5 80 108.5 75s5.1-9.1 11.5-9.1 11.4 4 11.4 9.1zM66 75c0 5-5.1 9.1-11.5 9.1C48.2 84.1 43 80 43 75s5.1-9.1 11.5-9.1S66 69.9 66 75zm43.8 69.3H64.6c2.2-10.4 11.5-18.3 22.6-18.3s20.4 7.9 22.6 18.3zm0 0"
      />
    </svg>
  );
}

KoalaIcon.propTypes = {
  className: PropTypes.string,
};

export { KoalaIcon };
