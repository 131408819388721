import PropTypes from 'prop-types';
import * as React from 'react';

function EucalyptusLeafIcon(props) {
  return (
    <svg viewBox="0 0 164 206.4" {...props}>
      <path
        d="M63.2 84.7C44.5 64.7 34.4 41.9 29 25.1c3.1 3.3 7.3 7.8 12.4 12.8l2.4-2.5c-9.1-8.8-15.1-15.7-17.1-18.1C24 7.3 23.2.7 23.2.6l-4.1.4c0 .4 3.2 28 20.2 57.9-.8 1.5-2.3 4.3-4.4 8.4l3.1 1.5c1.3-2.7 2.5-4.9 3.3-6.5 5.1 8.4 11.3 17 18.9 25.1l3-2.7z"
        fill="#856b54"
      />
      <path
        fill="#bdc79c"
        d="M36.5 68.1S10.7 75.3 5.2 125c0 0-5.5 45.2 0 66.2 0 0 4.4-29.2 16-49.7 0 0 30.6-49.7 15.3-73.4z"
      />
      <path
        fill="#78913b"
        d="M5.5 198l-1.7-6.4c-5.5-21-.3-64.9 0-66.7 5.6-50 31.2-57.7 32.3-58l1-.3.5.9c15.6 24.2-14 72.8-15.3 74.9-11.3 19.8-15.7 48.7-15.8 49l-1 6.6zM35.9 69.8c-4.6 1.9-24.6 12.8-29.4 55.4 0 .4-4.4 36.5-1.4 58.7 2.1-10.1 6.7-28.7 14.8-42.9.4-.7 29.3-48.1 16-71.2z"
      />
      <path
        fill="#bdc79c"
        d="M60.1 84.3s-11.6 17 18.6 47.3c0 0 48.4 48 51.3 73.7 0 0-2.2-53-28.2-91.5 0 0-23.9-35.2-41.7-29.5z"
      />
      <path
        fill="#78913b"
        d="M128.6 205.4c-2.8-24.9-50.4-72.4-50.9-72.8C47 101.9 58.5 84.3 59 83.6l.3-.4.4-.1c1.4-.5 2.9-.7 4.5-.7 17.6 0 37.8 29.4 38.7 30.7 25.9 38.4 28.4 91.6 28.5 92.2l-2.8.1zM61 85.5c-1.4 2.6-7.8 18.7 18.6 45.1 1.7 1.7 35.6 35.5 47.7 61.5-2.4-18.1-9-51.4-26.7-77.6-.2-.3-20.2-29.5-36.4-29.5-1.1.1-2.2.2-3.2.5z"
      />
      <path
        fill="#78913b"
        d="M128.9 198.8c-.2-1-.4-2.2-.6-3.4-.2-1.2-.7-2.5-1-3.9-.8-2.8-1.5-6-2.9-9.3-1.3-3.3-2.6-7-4.2-10.7-1.7-3.7-3.4-7.7-5.5-11.6-2.2-3.9-4.2-8-6.6-12-2.4-4-4.8-8.1-7.4-12.1-2.6-4-5.3-7.9-8-11.7-2.6-3.9-5.4-7.5-8-11.1-2.6-3.6-5.4-6.9-7.9-10.1-2.6-3.1-5.1-6.1-7.3-8.8-3.3-3.9-6.3-7.2-8.8-9.9-.3.2-.6.3-.8.5 2.2 2.9 4.9 6.5 8.1 10.6 4.3 5.6 9.1 12.3 14.3 19.5 2.6 3.6 5.2 7.3 7.8 11.2 2.6 3.8 5.2 7.8 7.7 11.8 2.6 3.9 5.1 7.9 7.5 11.9 2.5 3.9 4.6 8 6.8 11.8 2.1 3.9 4 7.7 5.9 11.3 1.8 3.6 3.2 7.2 4.7 10.4.8 1.6 1.3 3.2 1.8 4.7.6 1.5 1.1 2.9 1.6 4.3s1 2.6 1.4 3.8c.3 1.2.6 2.3.9 3.3 1.1 4 1.7 6.3 1.7 6.3s-.5-2.7-1.2-6.8z"
      />
      <path
        fill="#bdc79c"
        d="M41.4 36.9s16.7-17.3 61.4 27.9c0 0 29.5 31.2 59.6 37.7 0 0-79.5-2.8-114.8-44 0 .1-8.2-10.2-6.2-21.6z"
      />
      <path
        fill="#78913b"
        d="M162.4 103.9c-.8 0-80.5-3.3-115.8-44.4-.4-.5-8.6-10.9-6.5-22.8l.1-.4.3-.3c.1-.2 3.6-3.7 11.3-3.7 9.5 0 26.2 5.5 52 31.6.3.3 29.6 31 58.9 37.3l-.3 2.7zM42.7 37.6c-1.6 10.5 5.9 20 6 20.1 26.2 30.6 78 39.8 101.5 42.4-25.5-10.3-47.4-33.2-48.4-34.4-40-40.5-56.7-30-59.1-28.1zm-6.2 30.5s-.8 1.9-2.1 5.1c-1.3 3.2-3.2 7.9-5.3 13.5-2.2 5.6-4.5 12.2-6.9 19.3-1.2 3.5-2.3 7.2-3.5 11-1.2 3.7-2.2 7.6-3.3 11.4-2.1 7.7-3.9 15.4-5.4 22.8-.8 3.7-1.3 7.2-1.9 10.6-.5 3.4-.9 6.6-1.3 9.6-.3 3-.6 5.7-.8 8.1-.3 2.4-.3 4.5-.5 6.3-.2 3.5-.4 5.5-.4 5.5s-.1-2-.3-5.5c-.1-1.8-.2-3.9-.2-6.3s0-5.2.2-8.2c.2-3 .4-6.3.8-9.7.5-3.4.7-7.1 1.4-10.8.6-3.7 1.3-7.6 2.2-11.4.8-3.9 1.8-7.8 2.8-11.7 2.2-7.8 4.6-15.5 7.3-22.5 1.2-3.6 2.7-6.9 4-10.1 1.4-3.2 2.6-6.2 3.9-8.9 2.5-5.5 4.9-9.9 6.5-13 1.8-3.4 2.8-5.1 2.8-5.1z"
      />
      <path
        fill="#78913b"
        d="M42.6 36.6s1.6 1.4 4.5 3.8c2.8 2.4 7 5.7 11.9 9.7 2.5 2 5.2 4.1 8.1 6.3 2.9 2.2 6.1 4.4 9.3 6.7 3.3 2.2 6.6 4.6 10.1 6.8 3.5 2.3 7.1 4.5 10.7 6.6 3.6 2.2 7.3 4.2 11 6.2 3.7 1.9 7.3 3.8 10.9 5.4 3.6 1.7 7.1 3.2 10.4 4.6 3.3 1.5 6.6 2.5 9.6 3.6s5.8 1.8 8.2 2.7c2.5.8 4.6 1.4 6.4 1.8 3.6 1 5.7 1.5 5.7 1.5s-2.1-.3-5.8-.8c-1.8-.3-4.1-.6-6.6-1.2s-5.5-1.1-8.5-2.1c-3.1-1-6.5-1.8-9.9-3.1-3.5-1.2-7.1-2.5-10.8-4.2-3.7-1.5-7.5-3.3-11.2-5.2-3.7-1.9-7.5-4-11.2-6.2-3.7-2.2-7.3-4.5-10.8-6.8-3.5-2.3-6.8-4.8-10.1-7.2-6.4-4.8-12.1-9.7-16.9-13.9-4.8-4.3-8.5-8.1-11.1-10.7-2.4-2.7-3.9-4.3-3.9-4.3z"
      />
    </svg>
  );
}

EucalyptusLeafIcon.propTypes = {
  className: PropTypes.string,
};

export { EucalyptusLeafIcon };
