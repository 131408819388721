import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import { FigCaption } from './figcaption';

function Hero({ image, altText }) {
  return (
    <article className="relative w-full mx-auto overflow-hidden max-w-7xl">
      <div className="relative h-0 aspect-w-16 aspect-h-9">
        <figure className="absolute inset-0 flex">
          <Image fluid={image} alt={altText || ''} className="flex-1" />
          {altText && <FigCaption caption={altText} textAlign="text-right" />}
        </figure>
      </div>
    </article>
  );
}

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  altText: PropTypes.string,
};

export { Hero };
