import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  EucalyptusLeafIcon,
  EucalyptusLeafTwoIcon,
  KoalaIcon,
} from './vectors';

function FindOutMore({ variant = 'WHITE' }) {
  const VARIANT_MAP = {
    GRAY: 'bg-gray-light',
    WHITE: 'bg-white',
  };
  return (
    <article className={`mx-auto max-w-7xl ${VARIANT_MAP[variant]}`}>
      <div className="w-full max-w-4xl px-6 py-12 mx-auto">
        <h2 className="text-4xl font-bold tracking-wider text-center text-green">
          Find Out More
        </h2>
        <div className="flex flex-col justify-between mt-8 space-y-8 md:flex-row md:space-y-0">
          {[
            {
              heading: 'Koala <br/> Habitat',
              label: 'koala-habitat',
              icon: EucalyptusLeafIcon,
              slug: '/habitat/',
            },
            {
              heading: 'Understanding <br/> Koala Problems',
              label: 'understanding-koala-problems',
              icon: KoalaIcon,
              slug: '/koala-problems/',
            },
            {
              heading: 'The Tree <br/> Farm Project',
              label: 'the-tree-farm-project',
              icon: EucalyptusLeafTwoIcon,
              slug: '/tree-farm-project/',
            },
          ].map((node) => (
            <div key={node.label} className="flex flex-col items-center">
              <node.icon className="h-32 mx-auto" />
              <h3
                id={node.label}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: node.heading }}
                className="mt-4 text-xl font-bold text-center uppercase text-green"
              />
              <Link
                aria-labelledby={node.label}
                to={node.slug}
                className="inline-block px-8 py-1 mt-4 font-bold uppercase border-2 rounded-full border-green text-green"
              >
                Learn more
              </Link>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

FindOutMore.propTypes = {
  variant: PropTypes.oneOf(['WHITE', 'GRAY']),
};

export { FindOutMore };
