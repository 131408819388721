import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import { FigCaption } from './figcaption';

function CopyWithWideImages({ children, images, reverse, variant = 'WHITE' }) {
  const VARIANT_MAP = {
    WHITE: 'bg-white',
    GREEN: 'bg-green',
    GRAY: 'bg-gray-light'
  };
  return (
    <article className={`mx-auto max-w-7xl ${VARIANT_MAP[variant]}`}>
      <div className="items-center w-full grid-cols-5 gap-16 px-6 py-12 lg:grid ">
        <div className="col-span-3 text-green">{children}</div>
        <div
          className={`col-span-2 mt-8 space-y-8 lg:mt-0 ${
            reverse ? 'lg:order-first' : ''
          }`}
        >
          {images.map((image, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="relative h-0 overflow-hidden rounded-xl aspect-w-16 aspect-h-9 lg:aspect-w-4 lg:aspect-h-3"
            >
              <figure className="absolute inset-0 flex">
                <GatsbyImage
                  fluid={image.asset?.fluid || image.image}
                  imgStyle={{
                    objectPosition:
                      image.hotspot?.x && image.hotspot?.y
                        ? `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%`
                        : undefined,
                  }}
                  className="flex-1"
                />
                {image.altText && <FigCaption caption={image.altText} />}
              </figure>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

CopyWithWideImages.propTypes = {
  children: PropTypes.node.isRequired,
  images: PropTypes.array.isRequired,
  reverse: PropTypes.bool,
  variant: PropTypes.oneOf(['WHITE', 'GREEN', 'GRAY']),
};

export { CopyWithWideImages };
