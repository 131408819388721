import PropTypes from 'prop-types';
import * as React from 'react';

export function EucalyptusLeafTwoIcon(props) {
  return (
    <svg viewBox="0 0 164 235.7" {...props}>
      <path
        d="M115.5 187.4c5.9-1.2 11.6-2.7 15.3-4.3l-1.4-3.1c-7.3 3.3-23.5 5.9-33 7.2 5.5-4.2 7.5-7.9 7.6-8.1l-3-1.7c0 .1-3.2 5.7-12.4 10.8-17.1 3.6-30 10.7-37.3 15.6 13-24.2 31-46.3 31.2-46.6l-3.2-2.6c-.8 1-12.1 14.8-23.2 32.5-.4-7.9-2.9-19.6-7.6-35.3l-3.3 1c7.4 24.5 8.2 35.5 7.2 40.3-7.7 13.1-14.8 27.7-17.4 40.7l4 .8c1.5-7.6 4.7-15.8 8.7-24 3.5-2.8 15.4-11.6 33-16.9 3 1.1 9.6 4 11.5 6.4l2.7-2.1c-1.7-2.1-5.6-4.3-8.8-5.7 1.1-.3 2.1-.5 3.2-.7.7-.1 11.6-1.3 22.5-3.4 1.3 2.5 4.8 8.5 9.3 12.2l2.2-2.6c-3.4-3.1-6.3-7.8-7.8-10.4z"
        fill="#856b54"
      />
      <path
        fill="#bdc79c"
        d="M91.5 113.4s22.6-50.6 69.5-72.6c0 0-28.9 83.9-85.5 123l16-50.4z"
      />
      <path
        fill="#78913b"
        d="M73 167.2L90.2 113c.3-.7 23.4-51.4 70.2-73.5l2.9-1.4-1.1 3.1c-.3.8-29.7 84.7-86 123.7l-3.2 2.3zm85.5-123.7c-43.8 22.3-65.5 70-65.7 70.5l-14.7 46.3c47.3-34.9 74.6-101.5 80.4-116.8z"
      />
      <path
        fill="#bdc79c"
        d="M49 155.8S57.3 81 2.1 34.7c0 0-4 88.6 46.9 121.1z"
      />
      <path
        fill="#78913b"
        d="M50.1 158.2l-1.9-1.2C-2.7 124.4.7 35.5.7 34.6l.1-2.8L3 33.6C58.1 79.9 50.4 155.2 50.3 156l-.2 2.2zM3.4 37.6C3.3 52.8 5.2 124 47.8 153.3c.8-12.6 2-74.8-44.4-115.7z"
      />
      <path
        fill="#bdc79c"
        d="M73.1 166.7s45.5-76.2 3.2-158.5c0 0 4.6 53.5-6.9 79.2 0 0-18.5 37.6 3.7 79.3z"
      />
      <path
        fill="#78913b"
        d="M73.1 169.5l-1.1-2.1c-22.3-41.8-3.9-80.1-3.7-80.5 11.2-25 6.9-78 6.8-78.5l-.6-6.9 3.2 6.2c42.1 81.9-2.8 159-3.3 159.8l-1.3 2zm5.1-154.4c.8 15.5 1.6 52.5-7.5 72.9-.2.4-17.3 36.1 2.5 75.7 6.7-12.7 37.5-78.1 5-148.6z"
      />
      <path
        fill="#ffc721"
        d="M98.2 164.5c3.6-4.4 10.1-4.9 14.5-1.3 4.4 3.6 4.9 10.1 1.3 14.5-3.6 4.4-10.1 4.9-14.5 1.3-4.4-3.7-4.9-10.2-1.3-14.5zm50.1 12c1.3 5.5-2.2 11-7.8 12.3-5.5 1.3-11-2.2-12.3-7.8-1.3-5.5 2.2-11.1 7.8-12.3 5.5-1.2 11 2.2 12.3 7.8zm-22.6 19.1c5.5-1.2 11 2.3 12.3 7.8 1.2 5.5-2.3 11-7.8 12.3-5.5 1.2-11-2.3-12.3-7.8-1.2-5.5 2.3-11 7.8-12.3zm-30.2 0c5.5-1.2 11 2.3 12.3 7.8 1.2 5.5-2.3 11-7.8 12.3-5.5 1.2-11-2.3-12.3-7.8-1.3-5.5 2.2-11 7.8-12.3z"
      />
      <path
        fill="#78913b"
        d="M77.9 11.2s.7 2.4 1.6 6.7c.9 4.3 2.2 10.4 3.2 17.7 1.1 7.4 2.2 16 2.8 25.4.3 4.7.5 9.5.7 14.4 0 4.9.3 9.9.1 14.9-.2 5-.3 10-.7 14.9-.4 4.9-.7 9.7-1.3 14.4-.5 4.7-1.1 9.1-2 13.3-.9 4.2-1.4 8.2-2.5 11.8-.9 3.6-1.8 6.9-2.6 9.9-.9 2.9-1.8 5.4-2.5 7.5-1.4 4.1-2.3 6.4-2.3 6.4s.6-2.4 1.6-6.6c.5-2.1 1.2-4.6 1.9-7.6.5-3 1.2-6.3 1.9-10 .8-3.6 1.2-7.6 1.9-11.8.7-4.2 1-8.7 1.5-13.3.6-4.6.8-9.4 1.1-14.2.4-4.8.3-9.8.5-14.7.2-4.9.1-9.9.1-14.8-.1-4.9-.2-9.7-.5-14.3-.3-4.6-.4-9.1-.8-13.4-.3-4.2-.6-8.3-1-12-.7-7.4-1.4-13.6-2-17.9-.4-4.2-.7-6.7-.7-6.7zm81.3 30.5s-1 1.7-2.9 4.6c-1.9 2.9-4.5 7-7.8 11.8-3.3 4.8-7.1 10.5-11.4 16.5-4.2 6-8.9 12.3-13.5 18.5-4.7 6.2-9.5 12.4-14.1 18.1-2.3 2.9-4.5 5.6-6.7 8.2-2.2 2.6-4.2 5-6.1 7.2-3.8 4.5-7.3 7.9-9.6 10.4-2.5 2.4-3.9 3.7-3.9 3.7s1.2-1.5 3.4-4.2c1.1-1.3 2.3-3 3.8-4.8 1.4-1.9 3.1-4 4.8-6.3 3.6-4.6 7.6-10.2 12-16 4.4-5.9 9-12.1 13.7-18.3 4.7-6.2 9.4-12.4 13.8-18.3 4.5-5.8 8.6-11.2 12.2-15.8 3.6-4.6 6.7-8.4 8.8-11.1 2.2-2.7 3.5-4.2 3.5-4.2zm-157.1-7s.9 1.7 2.5 4.8C6 42.6 8.3 47 10.7 52.4c2.4 5.4 5.3 11.6 8.2 18.4 2.9 6.8 5.8 14.1 8.7 21.4 2.8 7.4 5.6 14.7 8 21.7 2.4 7 4.4 13.6 6.2 19.2 3.4 11.3 5.1 19 5.1 19s-.7-1.8-1.9-5.1c-1.1-3.3-3-7.8-5-13.3-4.2-11-9.9-25.7-15.6-40.3-2.8-7.3-5.7-14.7-8.3-21.5C13.5 65 11 58.6 8.9 53.1c-2.1-5.5-3.8-10.2-5-13.4-1.1-3.2-1.8-5-1.8-5z"
      />
    </svg>
  );
}

EucalyptusLeafTwoIcon.propTypes = {
  className: PropTypes.string,
};
