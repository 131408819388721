import * as React from 'react';
import { HiPhone } from 'react-icons/hi';

import { useGraphQL } from '../hooks';

function RescueService() {
  const { sanitySiteSettings } = useGraphQL();
  return (
    <article className="mx-auto bg-gray-light max-w-7xl">
      <div className="w-full px-6 py-12 text-center text-green">
        <h2 className="text-4xl font-bold tracking-wider uppercase md:text-5xl">
          24 hour Koala rescue service
        </h2>
        <p className="font-medium tracking-wider uppercase">
          In the manning, great lakes &amp; Gloucester area
        </p>
        <a
          href={`tel:${sanitySiteSettings.rescueNumber}`}
          className="inline-flex items-center py-1 mt-4 space-x-2 font-bold tracking-wide text-white rounded-full px-14 bg-green"
        >
          <HiPhone />
          <span>{sanitySiteSettings.rescueNumber}</span>
        </a>
      </div>
    </article>
  );
}

export { RescueService };
