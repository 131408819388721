/**
 * Keep all static queries in this file.
 * Add new queries as necessary.
 * You add aliases if you need to access the same node more than once.
 * The `placeholderImage` is an example of an alias on the `file` node.
 *
 * Example of how to use this hook:
 * import { useGraphQL } from './src/hooks';
 *
 * const { site } = useGraphQL();
 *
 * return <h1>{site.siteMetadata.title}</h1>;
 */

import { graphql, useStaticQuery } from 'gatsby';

function useGraphQL() {
  return useStaticQuery(
    graphql`
      query UseGraphQL {
        site {
          siteMetadata {
            title
          }
        }
        sanitySiteNavigation(_id: { eq: "siteNavigation" }) {
          items {
            _key
            link
            route
            title
          }
        }
        sanitySiteSettings(_id: { eq: "siteSettings" }) {
          bannerText
          description
          email
          facebook
          facilityManager
          mailingAddress
          officeNumber
          president
          rescueNumber
          siteUrl
          title
          membershipForm {
            asset {
              url
            }
          }
        }
        contactHeroImage: file(relativePath: { eq: "contact-hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, traceSVG: { color: "#045e22" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        ACNCBadgeImage: file(relativePath: { eq: "ACNC-badge.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );
}

export { useGraphQL };
