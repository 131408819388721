import {
  Menu,
  MenuButton,
  MenuItems,
  MenuLink,
  MenuPopover,
} from '@reach/menu-button';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useGraphQL } from '../hooks/use-graphql';
import { MobileMenu } from './mobile-menu';
import PropTypes from 'prop-types';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { sanitySiteNavigation } = useGraphQL();
  const navigationItems = [
    {
      _key: 123,
      link: null,
      route: '/',
      title: 'Home',
      submenus: [],
    },
    {
      _key: 124,
      link: null,
      route: '/',
      title: 'About',
      submenus: [
        {
          _key: 125,
          link: null,
          route: '/about/',
          title: 'Who Are Koalas In Care Inc.',
        },
      ],
    },
    {
      _key: 126,
      link: null,
      route: '/',
      title: 'Koalas',
      submenus: [
        {
          _key: 127,
          link: null,
          route: '/koala-problems/',
          title: 'Koala Problems',
        },
        {
          _key: 128,
          link: null,
          route: '/habitat/',
          title: 'Habitat',
        },
      ],
    },
    {
      _key: 129,
      link: null,
      route: '/',
      title: 'Projects',
      submenus: [
        {
          _key: 130,
          link: null,
          route: '/tree-farm-project/',
          title: 'Tree Farm Project',
        },
        {
          _key: 131,
          link: null,
          route: '/',
          title: 'Koala Conservation Area',
        },
      ],
    },
    {
      _key: 132,
      link: null,
      route: '/',
      title: 'Contact',
      submenus: [
        {
          _key: 133,
          link: null,
          route: '/contact/',
          title: 'Contact Us',
        },
        {
          _key: 134,
          link: null,
          route: '/join-us/',
          title: 'Join Us',
        },
      ],
    },
  ];
  return (
    <nav className="sticky top-0 z-20 w-full mx-auto bg-green max-w-7xl">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-end py-2 lg:py-0 lg:justify-between">
          <ul className="hidden w-full space-x-8 lg:flex lg:items-center lg:justify-between">
            {navigationItems.map(
              (navItem) =>
                navItem.submenus.length > 0 ? (
                  <Menu key={navItem._key} className="">
                    {({ isExpanded }) => (
                      <SubMenu navItem={navItem} isExpanded={isExpanded} />
                    )}
                  </Menu>
                ) : (
                  <li key={navItem._key}>
                    <Link
                      to={navItem.route}
                      className="inline-flex items-center px-2 py-8 text-base font-medium leading-5 tracking-wide text-white"
                    >
                      {navItem.title}
                    </Link>
                  </li>
                )
              // item.route ? (
              //   <Link
              //     key={item._key}
              //     to={item.route}
              //     className={`inline-flex items-center px-2 py-8 text-base font-medium leading-5 tracking-wide text-white ${
              //       pathname === item.route ? 'underline' : ''
              //     }`}
              //   >
              //     {item.title}
              //   </Link>
              // ) : (
              //   <a
              //     key={item._key}
              //     href={item.link}
              //     className={`inline-flex items-center px-2 py-8 text-base font-medium leading-5 tracking-wide text-white ${
              //       pathname === item.route ? 'underline' : ''
              //     }`}
              //   >
              //     {item.title}
              //   </a>
              // )
            )}
          </ul>
          <div className="flex items-center -mr-2 lg:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              onClick={() => setIsOpen((prevState) => !prevState)}
              aria-label="Main menu"
              aria-expanded={isOpen}
              className="inline-flex items-center justify-center p-2 text-white focus:outline-none"
            >
              {isOpen ? (
                <svg
                  className="block w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </nav>
  );
}

function SubMenu({ navItem, isExpanded }) {
  return (
    <li>
      <MenuButton className="relative pr-5 text-base font-medium leading-5 tracking-wide text-white">
        {navItem.title}
        <motion.span
          aria-hidden
          focusable="false"
          initial={{ rotate: 0 }}
          animate={{ rotate: isExpanded ? 90 : 0 }}
          exit={{ rotate: 0 }}
          className="absolute inset-y-0 right-0 flex items-center justify-center"
        >
          <svg
            aria-hidden
            focusable={false}
            fill="currentColor"
            viewBox="0 0 20 20"
            className="w-4 h-4 text-white"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </motion.span>
      </MenuButton>
      <MenuPopover className="z-30 p-0 mt-4 outline-none">
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              variants={{
                open: { opacity: 1, y: 0 },
                closed: { opacity: 0, y: '-0.25rem' },
              }}
              initial="closed"
              animate="open"
              exit="closed"
              className="rounded-lg shadow-lg"
            >
              <div className="flex py-2 text-white bg-white rounded-lg shadow-xs">
                <MenuItems className="py-0 bg-white border-none">
                  {navItem.submenus.map((submenu,index) => (
                    <>
                      <MenuLink
                        key={submenu._key}
                        as={Link}
                        to={submenu.route}
                        className="px-4 py-1 text-base font-medium leading-5 tracking-wide text-green"
                      >
                        {submenu.title}
                      </MenuLink>
                      {index < navItem.submenus.length-1 && <div className='mb-2' />}
                    </>
                  ))}
                </MenuItems>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </MenuPopover>
    </li>
  );
}

SubMenu.propTypes = {
  isExpanded: PropTypes.bool,
  navItem: PropTypes.object,
};

export { Header };
