import * as React from 'react';

import { useGraphQL } from '../hooks';
import { FacebookIcon } from './vectors';

function ContactInfo() {
  const { sanitySiteSettings } = useGraphQL();
  return (
    <dl className="max-w-lg space-y-3 text-left">
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>Office number:</dt>
        <dd className="font-semibold">
          <a
            href={`tel:${sanitySiteSettings.officeNumber}`}
            className="hover:underline"
          >
            {sanitySiteSettings.officeNumber}
          </a>
        </dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>24hr Koala Rescue:</dt>
        <dd className="font-semibold">
          <a
            href={`tel:${sanitySiteSettings.rescueNumber}`}
            className="hover:underline"
          >
            {sanitySiteSettings.rescueNumber}
          </a>
        </dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>Mailing Address:</dt>
        <dd className="font-semibold">{sanitySiteSettings.mailingAddress}</dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>Email:</dt>
        <dd className="font-semibold">
          <a
            href={`mailto:${sanitySiteSettings.email}`}
            className="hover:underline"
          >
            {sanitySiteSettings.email}
          </a>
        </dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>President:</dt>
        <dd className="font-semibold">{sanitySiteSettings.president}</dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>
          Koala Facility Manager: <br />
          Vice President
        </dt>
        <dd className="font-semibold">{sanitySiteSettings.facilityManager}</dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>NPWS Licence No.</dt>
        <dd className="font-semibold">MWL000100263</dd>
      </div>
      <div className="grid py-1 gap-x-4 sm:grid-cols-2 text-green">
        <dt>Social Media:</dt>
        <dd className="font-semibold">
          <a
            href={sanitySiteSettings.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block font-semibold text-green"
          >
            <span className="sr-only">Facebook</span>
            <FacebookIcon className="w-6 h-6" />
          </a>
        </dd>
      </div>
    </dl>
  );
}

export { ContactInfo };
