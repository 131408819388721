import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';

function Background() {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className="fixed inset-0 flex h-screen">
      <GatsbyImage fluid={file.childImageSharp.fluid} alt="" className="flex-1" />
    </div>
  );
}

export { Background };
