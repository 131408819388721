import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import { FigCaption } from './figcaption';

function CopyWithFullHeightImage({ image, children, variant = 'GRAY' }) {
  const VARIANT_MAP = {
    WHITE: 'bg-white',
    GREEN: 'bg-green text-white',
    GRAY: 'bg-gray-light',
  };
  return (
    <article className={`w-full mx-auto max-w-7xl ${VARIANT_MAP[variant]}`}>
      <div className="grid lg:grid-cols-5">
        <div className="lg:col-span-2">
          <div className="relative h-0 aspect-w-16 aspect-h-9 lg:aspect-none lg:h-full lg:flex lg:relative">
            <figure className="absolute inset-0 flex flex-1">
              <GatsbyImage
                fluid={image.asset?.fluid || image.image}
                imgStyle={{
                  objectPosition:
                    image.hotspot?.x && image.hotspot?.y
                      ? `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%`
                      : null,
                }}
                className="flex-1"
              />
              {image.altText && <FigCaption caption={image.altText} />}
            </figure>
          </div>
        </div>
        <div className="order-first px-4 py-16 lg:order-none lg:col-span-3 sm:px-6 lg:px-8">
          {children}
        </div>
      </div>
    </article>
  );
}

CopyWithFullHeightImage.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['WHITE', 'GREEN']),
};

export { CopyWithFullHeightImage };
