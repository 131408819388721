import { DialogContent, DialogOverlay } from '@reach/dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import * as React from 'react';

import { useGraphQL } from '../hooks';

function MobileMenu({ isOpen, setIsOpen }) {
  const { sanitySiteNavigation } = useGraphQL();

  // Reach Dialog is supposed to handle closing the using the 'Escape' key but it doesn't seem to work so we're doing it ourselves
  const handleEscape = (e) => {
    if (typeof document !== 'undefined' && e.key === 'Escape') {
      setIsOpen(false);
    }
  };
  if (typeof document !== 'undefined') {
    document.addEventListener('keydown', handleEscape);
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <DialogOverlay
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 z-40 lg:hidden"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-40 flex justify-end bg-gray-600 bg-opacity-75"
          >
            <DialogContent
              as={motion.div}
              aria-label="Sidebar"
              initial={{ x: '100%' }}
              animate={{ x: '0%' }}
              exit={{ x: '100%' }}
              transition={{ min: 0, max: 100, bounceDamping: 9 }}
              className="relative flex flex-col flex-1 w-full max-w-xs py-4 bg-green focus:outline-none"
            >
              <div className="flex-shrink-0 w-14">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
              <div className="absolute top-0 left-0 p-1 -ml-14">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  aria-label="Close sidebar"
                  className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                >
                  <svg
                    className="w-6 h-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <nav className="px-2">
                  {sanitySiteNavigation.items.map((node) =>
                    node.route ? (
                      <Link
                        key={node._key}
                        to={node.route}
                        className="flex items-center px-2 py-2 mt-1 text-base font-medium leading-6 text-white ocus:outline-none"
                      >
                        {node.title}
                      </Link>
                    ) : (
                      <a
                        key={node._key}
                        href={node.link}
                        className="flex items-center px-2 py-2 mt-1 text-base font-medium leading-6 text-white ocus:outline-none"
                      >
                        {node.title}
                      </a>
                    )
                  )}
                </nav>
              </div>
            </DialogContent>
          </motion.div>
        </DialogOverlay>
      ) : null}
    </AnimatePresence>
  );
}

export { MobileMenu };
