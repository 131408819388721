import { Link } from 'gatsby';
import Image from 'gatsby-image';
import * as React from 'react';
import { HiPhone } from 'react-icons/hi';

import { useGraphQL } from '../hooks/use-graphql';

function Banner() {
  const { logoImage, sanitySiteSettings } = useGraphQL();
  return (
    <article className="relative w-full mx-auto overflow-hidden bg-green max-w-7xl">
      <nav className="grid w-full px-6 py-4 font-bold text-center text-white lg:grid-cols-6 max-w-7xl">
        <div className="lg:col-span-4 lg:col-start-2">
          <p className="uppercase">{sanitySiteSettings.bannerText}</p>
        </div>
        <div className="text-center">
          <a
            href={`tel:${sanitySiteSettings.rescueNumber}`}
            className="inline-flex items-center space-x-2 tracking-wide"
          >
            <HiPhone />
            <span>{sanitySiteSettings.rescueNumber}</span>
          </a>
        </div>
      </nav>
      <div className="bg-white">
        <div className="items-center justify-center px-6 py-8 mx-auto lg:space-x-8 lg:flex max-w-7xl">
          <Link
            to="/"
            className="flex-shrink-0 block max-w-xs mx-auto lg:mx-0 w-52 lg:w-64"
          >
            <Image
              fluid={logoImage.childImageSharp.fluid}
              loading="eager"
              className="w-full"
            />
          </Link>
          <div className="flex flex-col justify-center mt-8 lg:mt-0">
            <div className="max-w-2xl mx-auto text-3xl font-extrabold text-center uppercase sm:text-5xl lg:text-7xl text-green">
              Koalas In <br />
              Care Inc.
            </div>
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="self-center mt-6"
            >
              <input type="hidden" name="cmd" defaultValue="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                defaultValue="5XMPB8L4CQBGQ"
              />
              <button
                type="submit"
                className="px-8 py-1 text-white uppercase rounded-full bg-orange"
              >
                Donate
              </button>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export { Banner };
