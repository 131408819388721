import PropTypes from 'prop-types';
import * as React from 'react';

function FigCaption({ caption, textAlign = 'text-center' }) {
  return (
    <figcaption
      className={`absolute inset-x-0 bottom-0 px-4 py-2 text-white sm:px-6 lg:px-8 ${textAlign}`}
    >
      <div
        aria-hidden
        className="absolute inset-0 opacity-60 bg-gradient-to-t from-black"
      />
      <span className="relative font-medium text-shadow">{caption}</span>
    </figcaption>
  );
}

FigCaption.propTypes = {
  caption: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
};

export { FigCaption };
