import { Link } from 'gatsby';
import Image from 'gatsby-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';
import { ContactInfo } from './contact-info';

function Footer() {
  const {
    sanitySiteNavigation,
    site: { siteMetadata },
    ACNCBadgeImage,
    logoImage,
  } = useGraphQL();

  return (
    <footer className="relative w-full mx-auto bg-white max-w-7xl">
      <div className="grid grid-cols-5 px-4 py-12 overflow-hidden sm:px-6 lg:px-8 ">
        <div className="col-span-5 lg:col-span-2">
          <Image
            fluid={ACNCBadgeImage.childImageSharp.fluid}
            className="w-40 mx-auto lg:w-3/5"
            alt={siteMetadata.title}
          />
        </div>
        <div className="grid-cols-3 col-span-5 mt-8 md:grid lg:col-span-3 lg:mt-0">
          <div className="col-span-1">
            <nav className="mt-2 space-y-3">
              <h2 className="font-bold tracking-wider uppercase text-green">
                Links
              </h2>
              {sanitySiteNavigation.items.map((item) =>
                item.route ? (
                  <div key={item._key} className="py-1">
                    <Link
                      to={item.route}
                      className="inline-block text-base font-semibold text-green hover:underline"
                    >
                      {item.title}
                    </Link>
                  </div>
                ) : (
                  <div key={item._key} className="py-1">
                    <a
                      href={item.link}
                      className="text-base font-semibold text-green hover:underline"
                    >
                      {item.title}
                    </a>
                  </div>
                )
              )}
            </nav>
          </div>
          <div className="col-span-2 mt-8 md:mt-0">
            <ContactInfo />
            <div className="flex items-center mt-2 space-x-6">
              <div className="flex-shrink-0 w-20">
                <Link to="/" className="block">
                  <Image
                    fluid={logoImage.childImageSharp.fluid}
                    className="w-full"
                  />
                </Link>
              </div>
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
                className="self-center"
              >
                <input type="hidden" name="cmd" defaultValue="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  defaultValue="5XMPB8L4CQBGQ"
                />
                <button
                  type="submit"
                  className="w-full max-w-xs px-8 py-1 text-white uppercase rounded-full bg-orange"
                >
                  Donate
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-span-5 pt-8 mt-8 text-center border-t">
          <a
            href="https://pd.design"
            className="text-base leading-8 text-green"
          >
            Designed and developed by PD
          </a>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
