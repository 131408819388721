import { Link } from 'gatsby';
import * as React from 'react';

import { useGraphQL } from '../hooks';

function SupportMember() {
  const { sanitySiteSettings } = useGraphQL();
  return (
    <article className="w-full mx-auto bg-gray-light max-w-7xl">
      <div className="px-6 py-12 text-center text-green">
        <p className="font-bold tracking-wider uppercase">
          We cannot achieve this without you
        </p>
        <h2 className="text-4xl font-bold">
          Help by becoming a Support Member today
        </h2>
        <div className="mt-2">
          <a
            href={sanitySiteSettings.membershipForm.asset.url}
            className="inline-block py-1 font-bold tracking-wide border-2 rounded-full text-gray-light bg-green border-green px-14"
          >
            Download membership form
          </a>
        </div>
        <Link
          to="/join-us/"
          className="inline-block mt-4 font-bold tracking-wider underline uppercase text-green"
        >
          Learn more about our memberships
        </Link>
      </div>
    </article>
  );
}

export { SupportMember };
