import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Input, NetlifyForm, TextArea } from './form-elements';

function ContactForm() {
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <article className="relative mx-auto overflow-hidden bg-green max-w-7xl">
      <div className="relative max-w-4xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-center text-white">
            Get in touch
          </h2>
        </div>
        <div className="mt-12">
          <NetlifyForm
            handleSubmit={handleSubmit}
            setIsSubmitting={setIsSubmitting}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <Input
              name="full_name"
              label="Full name"
              register={register}
              errors={errors}
              isFullWidth
              labelHidden
            />
            <Input
              name="phone_number"
              label="Phone number"
              type="tel"
              labelHidden
              isFullWidth
              register={register}
              errors={errors}
            />
            <Input
              name="email"
              label="Email"
              type="email"
              labelHidden
              isFullWidth
              register={register}
              errors={errors}
            />
            <TextArea
              name="message"
              label="Message"
              register={register}
              errors={errors}
              labelHidden
            />
            <div className="flex justify-center sm:col-span-2">
              <span className="inline-flex w-full max-w-md shadow-sm">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`inline-flex items-center justify-center w-full px-6 py-1 tracking-wide text-base font-bold leading-6 text-white transition duration-150 ease-in-out bg-green border-2 uppercase border-white rounded-full ${
                    isSubmitting ? 'opacity-50 cursor-wait' : ''
                  }`}
                >
                  Submit
                </button>
              </span>
            </div>
          </NetlifyForm>
        </div>
      </div>
    </article>
  );
}

export { ContactForm };
