/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import * as React from 'react';

import { Background } from './background';
import { Banner } from './banner';
import { Footer } from './footer';
import { Header } from './header';
import { Hero } from './hero';

function Layout({ children, heroImage, heroAltText }) {
  return (
    <div className="relative flex flex-col min-h-screen font-sans antialiased text-gray-700 bg-white fill-available">
      <Background />
      <Banner />
      {heroImage && <Hero image={heroImage} altText={heroAltText} />}
      <Header />
      <main className="relative flex-1">{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  heroImage: PropTypes.object,
  heroAltText: PropTypes.string,
};

export { Layout };
